import axios from 'axios'

export default class directPrint{
    constructor(url) {
        this.url = url
    }
    async print(){
        let response = await axios({url: this.url, responseType: "blob"})

        let iframe = document.createElement('iframe');
        document.body.appendChild(iframe);

        iframe.style.display = 'none';

        iframe.src = window.URL.createObjectURL(response.data)
        await new Promise (resolve => iframe.onload = () => {
            iframe.contentWindow.onafterprint = () => document.body.removeChild(iframe)
            setTimeout(() => {
                iframe.focus();
                iframe.contentWindow.print();
                resolve()
            }, 1);
        });
    }
}