import gql from 'graphql-tag'

export const GET_ORGANIZATION_STOCK = gql`
    query ($toId:uuid!,$unitId:uuid!){
        ToOrganizationStock:OrganizationStock(where: {organizationId: {_eq: $toId}, unitId: {_eq: $unitId}}, order_by: {ProductUnit: {unit: desc}}){
            id
            organizationId
            locationId
            productId
            quantity
            unitId
            totalQuantityInPackets
        }
    }`
export const CREATE_UPDATE_ORGANIZATION_STOCK = gql`
    mutation ($updateToOrgStock:[OrganizationStock_insert_input!]!, $organizationStock_on_conflict_rule: OrganizationStock_on_conflict!) {
        ToOrg:insert_OrganizationStock(objects: $updateToOrgStock, on_conflict: $organizationStock_on_conflict_rule){
            returning{
                id
            }
        }
    }`
