<template>
  <div id="OrderDetail">
    <v-dialog persistent fullscreen scrollable v-model="orderDetailDialog">
      <v-card class="pa-5">
        <v-card-title>
          <h6 class="text-h6">Order Detail</h6>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('cancel')" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="order">
          <v-row>
            <v-col class="px-8" style="border-right: 1px solid #E0E0E0;" cols="3">
              <div class="mb-10 mt-5">
                <h1 class="text-h4 black--text font-weight-bold">Order
                  <v-btn icon title="Print Order" :loading="printLoading" @click.stop="onPrintOrder(order)" class="float-right"
                  v-if="![IDS.orderStatus.Canceled, IDS.orderStatus.Rejected].includes(order.status)">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </h1>
                <h6 class="text-caption font-weight-bold">ID : {{ order.orderNum }}</h6>
              </div>
<!--              <div>Order Number {{ order.orderNum }}</div>-->
              <v-btn :style="`background:${IDS.OrderStatusColor[order.status]}`" dark @click="openStatusMenu($event, order)"
                     class="elevation-0 mb-3" width="244">{{order.OrderStatus.name}}<v-icon right>mdi-menu-down</v-icon></v-btn>
              <change-order-status :statusMenu.sync="statusMenu" :positionX="menuPositionX" :positionY="menuPositionY"
                                   :orderId="orderId" :statusId="statusId"/>
              <v-card outlined class="rounded-0">
                <div class="pa-3 green lighten-5">
                  <h6 class="text-caption green--text text--lighten-2">Total Amount</h6>
                  <h3 class="text-body-1 green--text font-weight-medium">₹{{ order.roundOfValue }}</h3>
                </div>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Order Status</h6>
                  <h3 class="text-body-1">{{ order.OrderStatus.name }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Distributor Code</h6>
                  <h3 class="text-body-1">{{ order.Distributor.orgCode }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Distributor Name</h6>
                  <h3 class="text-body-1">{{ order.Distributor.name }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Mobile</h6>
                  <h3 class="text-body-1">{{ order.Distributor.mobile }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Invoice Number</h6>
                  <h3 class="text-body-1">{{ checkVal(order.tallyInvoiceNum) }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Invoice Date</h6>
                  <h3 class="text-body-1">{{ dateFormatter(order.invoiceDate) }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Order Date</h6>
                  <h3 class="text-body-1">{{ dateTimeFilter(order.created_at) }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3" v-if="[IDS.orderStatus.Dispatched, IDS.orderStatus.Delivered].includes(order.OrderStatus.id)">
                  <h6 class="text-caption grey--text">Expected Delivery Date</h6>
                  <h3 class="text-body-1">{{ dateFormatter(order.expectedDeliveryDate) }}</h3>
                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Created By</h6>
                  <h3 class="text-body-1">{{ order.CreatedBy&&order.CreatedBy.name }}</h3>
                </div>
              </v-card>
            </v-col>
            <v-col class="mt-5 px-8" cols="6" style="height: calc(100vh - 80px); overflow-y: auto">
              <div class="mb-10 ">
                <h1 class="text-h4 black--text font-weight-bold">Products</h1>
                <h6 class="text-caption font-weight-bold">{{ order.OrderItems.length }} ITEMS</h6>
              </div>
              <v-card elevation="0" class="py-0" v-for="(orderItem,index) in order.OrderItems" :key="index" cols="12">
                <v-card-text class="py-0">
                  <v-row>
                    <v-col cols="2" class="text-center px-0 img-div" @click="openGallery([{image: orderItem.Product.image}])">
                      <v-img class="d-block mx-auto" contain height="100"
                             :src="orderItem.Product.image?imgWpx(orderItem.Product.image, 200):'../src/assets/logo.png'"></v-img>
                      <v-chip label text-color="white" x-small v-if="orderItem.Product.packageType" class="mt-3"
                              :style="`background-color: ${packageColor[orderItem.Product.packageType]}`">
                        {{ orderItem.Product.packageType.toUpperCase() }}
                      </v-chip>
                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex justify-space-between align-start">
                        <h6 class="text-h6 font-weight-medium black--text">
                          {{ orderItem.Product.displayName }}</h6>
                        <span style="width: 20%" class="text-caption text-right font-weight-medium ">{{ orderItem.quantity }} {{orderItem.Product.containerType}}es</span>
                      </div>
                      <p class="text-caption font-weight-medium mb-0">{{ orderItem.Product.noOfPieceInBox }} Pieces in {{orderItem.Product.containerType}} ({{ currencyFormatter(orderItem.perUnitPrice) }}) </p>
                      <h6 class="text-subtitle-1 mt-3 font-weight-bold">{{ currencyFormatter(orderItem.price) }}</h6>
                    </v-col>
                    <v-col cols="12" class="pt-6 pb-9">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="px-8" style="border-left: 1px solid #E0E0E0;" cols="3">
              <div class="mb-10 mt-5">
                <h1 class="text-h4 black--text font-weight-bold">Pricing</h1>
                <h6 class="text-caption font-weight-bold">{{ totalQty }} BOXES ({{totalPieces}} PIECES)</h6>
              </div>

              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">Order Amount</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.totalAmount) }} </span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">CGST(6%)</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.gstPrice / 2 ) }}</span>
              </div>
              <div class="d-flex justify-space-between mb-2">
                <span class="text-body-1">SGST(6%)</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.gstPrice / 2) }}</span>
              </div>
              <div class="d-flex justify-space-between ">
                <span class="text-body-1">Total GST(+12%)</span>
                <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.gstPrice) }}</span>
              </div>
              <v-divider class="my-3"></v-divider>
              <div class="d-flex justify-space-between green--text mb-14">
                <span class="text-body-1  font-weight-medium">Payable Amount</span>
                <span class="text-body-1  font-weight-medium">{{ currencyFormatter(order.roundOfValue) }}</span>
              </div>
              <div class="border-box">
              <div class="pa-3">
                  <h6 class="text-caption grey--text">Total Product KG</h6>
                  <h3 class="text-body-1" style="color: black;">{{ totalProductKg }} KG</h3>

                </div>
                <v-divider></v-divider>
                <div class="pa-3">
                  <h6 class="text-caption grey--text">Order Total Loading Weight</h6>
                  <h3 class="text-body-1" style="color: black;">{{ totalLoadingWeight }} KG</h3>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <gallery id="gallery-order-detail" :images="galleryImages" :index="galleryIndex" @close="galleryIndex=null"/>
  </div>
</template>

<script>
import { SUBSCRIBE_SALES_ORDER } from '../../graphql/subscriptions/Orders'
import {IDS} from '../../Constants/IDS'
import ChangeOrderStatus from "./ChangeOrderStatus";
import directPrint from "../Common/Helpers/directPrint";
import Constants from "../../Constants/Constants";
import gallery from '../../mixins/gallery';
export default {
  name: 'OrderDetail',
  components: {ChangeOrderStatus},
  mixins: [gallery],
  props: {
    orderDetailDialog: { type: [Boolean], default: false },
    orderId: { type: String, default: null },
  },
  data () {
    return {
      IDS,
      order: null,
      statusId: null,
      statusMenu: false,
      menuPositionX: 0,
      menuPositionY: 0,
      printLoading: false,
      packageColor: JSON.parse(localStorage.packageColor),
    }
  },
  apollo: {
    $subscribe: {
      Order: {
        query: SUBSCRIBE_SALES_ORDER, fetchPolicy: 'network-only',
        skip () {return !this.orderId}, variables () {return { id: this.orderId }},
        result ({ data, loading }) { if (!loading) { this.order = data.PurchaseOrders[0] } },
      },
    },
  },
  computed: {
  totalQty: function() {
    return this.order?.OrderItems.reduce((ac, val) => ac + Number(val.quantity), 0)
  },
  totalPieces: function() {
    return this.order?.OrderItems.reduce((ac, val) => ac + (Number(val.quantity) * val.Product.bunchKg), 0)
  },
  totalProductKg: function() {
    if (this.order && this.order.totalProductKG) {
      return this.order.totalProductKG.toFixed(2); 
    }
    return 0; 
  },
  totalLoadingWeight: function() {
  
    if (this.order && this.order.totalLoadingWeight) {
   
      return this.order.totalLoadingWeight.toFixed(2); 
    }
    return 0; 
  }
},

  methods:{
    openStatusMenu(e, row){
            /* Disable for Delivered/Canceled/Rejected stage */
            if([IDS.orderStatus.Delivered, IDS.orderStatus.Canceled, IDS.orderStatus.Rejected].includes(row.status)) return;
      this.statusId = row.status;
      this.menuPositionX = e.clientX - e.offsetX - (e.target.clientWidth === 24?6:0);
      this.menuPositionY = e.clientY + e.target.clientHeight - e.offsetY + (e.target.clientHeight === 24?6:0);
      this.statusMenu = true;
    },
    async onPrintOrder(row){
      this.printLoading = true;
      let d = new directPrint(`${Constants.api_url}distributorOrderPdf/${row.id}`);
      await d.print();
      this.printLoading = false;
    },
  }
}
</script>
<style scoped>
  .img-div{
    cursor: pointer;
  }

  .border-box {
    color:lightgray;
    border: 1px solid ;
   
    margin-top: -30px;
  }
</style>