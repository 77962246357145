import gql from 'graphql-tag'

export const CREATE_UPDATE_PURCHASE_ORDER_ITEMS = gql`
    mutation ($orderObj:[PurchaseOrders_insert_input!]!, $orders_on_conflict_rule: PurchaseOrders_on_conflict!) {
        insert_PurchaseOrders(objects: $orderObj, on_conflict: $orders_on_conflict_rule){
            returning{
                id
            }
        }
    }`

export const UPDATE_ORDER_WITH_ORGANIZATION_STOCK = gql`
mutation ($id: uuid!, $order:PurchaseOrders_set_input, $orgStock:[OrganizationStock_insert_input!]!, $updateColumnsOrgStock: [OrganizationStock_update_column!]!) {
  update_PurchaseOrders(where: {id: {_eq: $id}}, _set: $order){
   affected_rows
  } 
  insert_OrganizationStock(objects: $orgStock, on_conflict: {constraint: OrganizationStock_pkey, update_columns: $updateColumnsOrgStock}){
   affected_rows
  }  
}`