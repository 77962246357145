<template>
<div>

    <v-menu v-model="menu" :position-x="positionX" :position-y="positionY" absolute offset-y :close-on-content-click="false">
        <v-list dense>
           <!-- <v-subheader style="margin-top: 15px">
                <v-text-field outlined dense placeholder="Search" @input="filterStatuses"></v-text-field>
                <v-progress-linear absolute bottom></v-progress-linear>
            </v-subheader> -->
            <v-list-item-group color="primary">
                <v-list-item v-for="(s, index) in statuses" :key="index" @click="onSelectStatus(s)">
                    <v-list-item-icon>
                        <v-icon :style="`color:${IDS.OrderStatusColor[s.id]}`">mdi-checkbox-blank</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> {{ s.name }}</v-list-item-title>
                    <v-progress-circular v-if="selectedStatusId===s.id" indeterminate :style="`color:${IDS.OrderStatusColor[s.id]}`"></v-progress-circular>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>

    <dispatch-order :dialog.sync="dispatchOrderDialog" :orderId="orderId"/>
    <deliver-order :dialog.sync="deliverOrderDialog" :orderId="orderId"/>
    <Snackbar :toaster="toaster"/>
</div>
</template>

<script>
import {GET_ORDER_STATUS} from "../../graphql/queries/Orders";
import {IDS} from "../../Constants/IDS";
import updateRecord from '../../mixins/updateRecord';
import Snackbar from "../Common/Snackbar";
import DispatchOrder from "./DispatchOrder";
import moment from 'moment';
import DeliverOrder from "./DeliverOrder";

export default {
    name: "ChangeOrderStatus",
    components: {DeliverOrder, DispatchOrder, Snackbar},
    props: ['statusMenu', 'positionX', 'positionY', 'orderId', 'statusId'],
    mixins: [updateRecord],
    apollo:{
        statuses: {query: GET_ORDER_STATUS }
    },
    data(){
        return{
            IDS,
            user: JSON.parse(localStorage.user),
            statuses: [],
            selectedStatusId: null,
            dispatchOrderDialog: false,
            deliverOrderDialog: false,
            toaster: {}
        }
    },
    computed: {
        menu: {
            get() { return this.statusMenu },
            set(value) { this.$emit('update:statusMenu', value) }
        }
    },
    methods:{
        async onSelectStatus(row){
            if(row.id === this.statusId) return;
            if(row.id === IDS.orderStatus.Dispatched) return this.dispatchOrderDialog = true;
            if(row.id === IDS.orderStatus.Delivered) return this.deliverOrderDialog = true;

            if(this.statusId === IDS.orderStatus.Dispatched && ![IDS.orderStatus.Delivered].includes(row.id))
                return this.toaster = { enable: true, color: 'red', message: 'Cannot change status of order other than delivered'};

            this.selectedStatusId = row.id;
            let obj = { status: row.id, updatedBy: this.user.id};
            /* Need to check 'delivered_at' exists already before update */
            if(row.id === IDS.orderStatus.Delivered) obj['delivered_at'] = moment().format();   /* When deliver Order */
            await this.updateRecord(this.orderId, obj, 'PurchaseOrders');
            this.toaster = { enable: true, color: 'green', message: 'Order Status updated successfully' };
            this.selectedStatusId = null;
            this.$emit('update:statusMenu', false);
        }
    }
}
</script>

<style scoped>

</style>