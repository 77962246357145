import gql from 'graphql-tag'
import {IDS} from "../../Constants/IDS";

export const SUBSCRIBE_SALES_ORDERS = gql`
    subscription ($orgType:[uuid!],$filter:PurchaseOrders_bool_exp, $search:String){
        PurchaseOrders:PurchaseOrders(where:{_and:[ $filter, {_or:[{orderNum:{_ilike:$search}},{Distributor:{_or:[{name:{_ilike:$search}},{mobile:{_ilike:$search}},{orgCode:{_ilike:$search}}]}}]} ]}){
            id
            orderNum
            tallyInvoiceNum
            orderItemCount
            created_at
            roundOfValue
            invoiceDate
            Distributor{
                id
                name
                mobile
                orgCode
            }
        }
    }`

export function generate_FETCH_SALES_ORDERS(name, type = 'query') {
 return gql`
  ${type} ${name}($startDate:timestamptz!, $endDate:timestamptz!, $status:uuid_comparison_exp, $distributorIds:uuid_comparison_exp, $search:String, $offset: Int!, $limit: Int!, $orderBy: [PurchaseOrders_order_by!]){
   ${name}: PurchaseOrders(where:{_and:[ { created_at: {_gte: $startDate}},{ created_at: {_lte: $endDate}}, { status: $status}, { to: $distributorIds}, 
        {_or:[{orderNum:{_ilike:$search}},{Distributor:{_or:[{name:{_ilike:$search}},{mobile:{_ilike:$search}},{orgCode:{_ilike:$search}}]}}]} ]}, 
        offset: $offset, limit: $limit, order_by: $orderBy ){
    id
    orderNum
    tallyInvoiceNum
    orderItemCount
    created_at
    roundOfValue
    invoiceDate
    status
    delivered_at
    expectedDeliveryDate
    totalProductKG
    totalLoadingWeight
    totalAmount
    finalAmount
    Distributor{ 
     id
     name
     mobile
     orgCode
     Users(where:{userTypeId:{_eq:"${IDS.userTypes.Owner}"},isKeyOwner:{_eq:true}}){ id name }                
    }                
 }
}`
}

export function generate_SUBSCRIBE_SALES_ORDERS_COUNT(name) {
 return gql`
    subscription ${name}($startDate:timestamptz!, $endDate:timestamptz!, $status:uuid_comparison_exp, $distributorIds:uuid_comparison_exp, $search:String){
    ${name}: PurchaseOrders_aggregate(where:{_and:[ { created_at: {_gte: $startDate}},{ created_at: {_lte: $endDate}}, { status: $status}, { to: $distributorIds}, 
            {_or:[{orderNum:{_ilike:$search}},{Distributor:{_or:[{name:{_ilike:$search}},{mobile:{_ilike:$search}},{orgCode:{_ilike:$search}}]}}]} ]} ){
            aggregate { 
                count 
            }   
            aggregate {
                sum {
                    finalAmount
                }
            }
            aggregate {
                sum {
                  totalLoadingWeight
                }
              }
              aggregate {
                sum {
                  totalProductKG
                }
              }             
        } 
    }`
}

export const SUBSCRIBE_SALES_ORDER = gql`
    subscription ($id:uuid!){
        PurchaseOrders:PurchaseOrders(where:{id:{_eq:$id}}){
            id
            orderNum
            tallyInvoiceNum
            invoiceDate
            gstObject
            status
            OrderStatus {id name}
            totalAmount
            totalProductKG
            totalLoadingWeight
            gstPrice
            finalAmount
            roundOfValue
            created_at
            updated_at
            expectedDeliveryDate
            Distributor{
                id
                name
                mobile
                orgCode
            }
            From{
                id
                name
            }
            CreatedBy{
                id
                name
            }
            OrderItems{
                id
                quantity
                price
                perUnitPrice
                Unit{
                    id
                    unit
                }
                Product{
                    id
                    name
                    image
                    weight
                    bunchKg
                    noOfPieceInBox
                    colorCode
                    bunchPrice
                    packageType
                    containerType
                    displayName
                }
            }
        }
    }`
