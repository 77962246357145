<template>
<div>

    <v-dialog persistent fullscreen scrollable v-model="dialog">
        <v-card class="pa-5">
            <v-card-title>
                <h6 class="text-h6">Dispatch Order</h6>
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog" icon><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear :active="$apollo.queries.PurchaseOrders.loading" :indeterminate="true"/>
            <v-card-text v-if="order">
                <v-row>
                    <v-col class="px-8" style="border-right: 1px solid #E0E0E0;" cols="3">
                        <div class="mb-10 mt-5">
                            <h1 class="text-h4 black--text font-weight-bold">Order</h1>
                            <h6 class="text-caption font-weight-bold">{{ order.orderNum }}</h6>
                        </div>
                        <v-card outlined class="rounded-0">
                            <div class="pa-5 green lighten-5">
                                <h6 class="text-caption green--text text--lighten-2">Total Amount</h6>
                                <h3 class="text-body-1 green--text font-weight-medium">₹{{ order.roundOfValue }}</h3>
                            </div>
                            <div class="pa-5">
                                <h6 class="text-caption grey--text">Distributor Code</h6>
                                <h3 class="text-body-1">{{ order.Distributor.orgCode }}</h3>
                            </div>
                            <v-divider></v-divider>
                            <div class="pa-5">
                                <h6 class="text-caption grey--text">Distributor Name</h6>
                                <h3 class="text-body-1">{{ order.Distributor.name }}</h3>
                            </div>
                            <v-divider></v-divider>
                            <div class="pa-5">
                                <h6 class="text-caption grey--text">Mobile</h6>
                                <h3 class="text-body-1">{{ order.Distributor.mobile }}</h3>
                            </div>
                            <v-divider></v-divider>
                     <!--       <div class="pa-5">
                                <h6 class="text-caption grey--text">Invoice Number</h6>
                                <h3 class="text-body-1">{{ checkVal(order.tallyInvoiceNum) }}</h3>
                            </div>
                            <v-divider></v-divider>
                            <div class="pa-5">
                                <h6 class="text-caption grey--text">Invoice Date</h6>
                                <h3 class="text-body-1">{{ dateFormatter(order.invoiceDate) }}</h3>
                            </div>
                            <v-divider></v-divider>
                            <div class="pa-5">
                                <h6 class="text-caption grey--text">Order Date</h6>
                                <h3 class="text-body-1">{{ dateTimeFilter(order.created_at) }}</h3>
                            </div>
                            <v-divider></v-divider> -->
                            <div class="pa-5">
                                <h6 class="text-caption grey--text">Created By</h6>
                                <h3 class="text-body-1">{{ (order.CreatedBy&&order.CreatedBy.name)||'-' }}</h3>
                            </div>
                        </v-card>
                    </v-col>
                    <v-col class="mt-5 px-8" cols="6" style="height: calc(100vh - 80px); overflow-y: auto">
                        <div class="mb-10">
                           <!-- <h1 class="text-h4 black--text font-weight-bold">Products</h1>
                            <h6 class="text-caption font-weight-bold">{{ order.OrderItems.length }} ITEMS</h6> -->
                            <v-form ref="form">
<!--                                <v-row>-->
<!--                                    <v-col cols="12">-->
                                        <v-text-field  outlined class="rounded-0" label="Tally Invoice Number" :rules="[v => !!v || 'Tally Invoice number required']" v-model="form.tallyInvoiceNum"/>

                                        <v-menu  :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                                                 transition="scale-transition" v-model="invoiceDateMenu">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field class="rounded-0" clearable  label="Invoice Date" outlined prepend-inner-icon="mdi-calendar-blank-outline"
                                                              readonly v-bind="attrs" v-model="invoiceDateFormat" v-on="on" :rules="[v => !!v || 'Invoice Date required']"/>
                                            </template>
                                            <v-date-picker @input="invoiceDateMenu = false" v-model="form.invoiceDate" :max="moment().format('YYYY-MM-DD')"/>
                                        </v-menu>

                                        <v-menu  :close-on-content-click="false" :nudge-right="40" min-width="290px" offset-y
                                                 transition="scale-transition" v-model="expectedDateMenu">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field class="rounded-0" clearable  label="Expected Delivery Date" outlined prepend-inner-icon="mdi-calendar-blank-outline"
                                                              readonly v-bind="attrs" v-model="expectedDateFormat" v-on="on" :rules="[v => !!v || 'Expected Delivery Date required']"/>
                                            </template>
                                            <v-date-picker @input="expectedDateMenu = false" v-model="form.expectedDeliveryDate" :min="moment().format('YYYY-MM-DD')"/>
                                        </v-menu>
<!--                                    </v-col>-->
<!--                                </v-row>-->
                                <div class="float-right">
                                    <v-btn color="primary" :loading="formLoading" @click="handleSubmit">Dispatch Order</v-btn>
                                    <v-btn @click="closeDialog" class="ml-3">Cancel</v-btn>
                                </div>
                            </v-form>
                        </div>
                    </v-col>
                    <v-col class="px-8" style="border-left: 1px solid #E0E0E0;" cols="3">
                        <div class="mb-10 mt-5">
                            <h1 class="text-h4 black--text font-weight-bold">Pricing</h1>
                        </div>

                        <div class="d-flex justify-space-between mb-2">
                            <span class="text-body-1">Order Amount</span>
                            <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.totalAmount) }} </span>
                        </div>
                        <div class="d-flex justify-space-between mb-2">
                            <span class="text-body-1">CGST(2.5%)</span>
                            <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.gstPrice / 2 ) }}</span>
                        </div>
                        <div class="d-flex justify-space-between mb-2">
                            <span class="text-body-1">SGST(2.5%)</span>
                            <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.gstPrice / 2) }}</span>
                        </div>
                        <div class="d-flex justify-space-between ">
                            <span class="text-body-1">Total GST(+{{ order.gstObject.gst }})</span>
                            <span class="text-body-1 font-weight-medium">{{ currencyFormatter(order.gstPrice) }}</span>
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <div class="d-flex justify-space-between green--text mb-14">
                            <span class="text-body-1  font-weight-medium">Payable Amount</span>
                            <span class="text-body-1  font-weight-medium">{{ currencyFormatter(order.roundOfValue) }}</span>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>

    <Snackbar :toaster="toaster"/>
</div>
</template>

<script>
import moment from 'moment';
import {GET_SALES_ORDER} from "../../graphql/queries/Orders";
import {IDS} from "../../Constants/IDS";
import Snackbar from "../Common/Snackbar";
import {GET_ORGANIZATION_STOCK} from "../../graphql/queries/OrganizationStock";
import {UPDATE_ORDER_WITH_ORGANIZATION_STOCK} from "../../graphql/mutations/OrderStock";
let user = JSON.parse(localStorage.user);
let form = { tallyInvoiceNum: null, invoiceDate:null, expectedDeliveryDate:null, status:IDS.orderStatus.Dispatched ,updatedBy:user.id };
export default {
    name: "DispatchOrder",
    components: {Snackbar},
    props: ['dialog', 'orderId'],
    apollo: {
        PurchaseOrders: {
            query: GET_SALES_ORDER,
            fetchPolicy: 'network-only',
            skip () {return !this.orderId || !this.dialog},
            variables () {return { id: this.orderId }},
            result ({ data}) { this.order = data.PurchaseOrders[0] }
        },
    },
    data() {
        return {
            moment: moment,
            form: {...form},
            formLoading: false,
            order: null,
            invoiceDateMenu: false,
            expectedDateMenu: false,
            toaster: {}
        }
    },
    computed: {
        invoiceDateFormat: {
            get () { return this.form.invoiceDate ? moment(this.form.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : null },
            set () { this.form.invoiceDate = null }
        },
        expectedDateFormat: {
            get () { return this.form.expectedDeliveryDate ? moment(this.form.expectedDeliveryDate, 'YYYY-MM-DD').format('DD-MM-YYYY') : null },
            set () { this.form.expectedDeliveryDate = null }
        },
    },
    methods:{
        handleSubmit(){
            if(this.$refs.form.validate()) this.dispatchOrder();
        },
        async dispatchOrder(){
            this.formLoading = true;

            let result = await this.$apollo.query({
                query: GET_ORGANIZATION_STOCK,
                fetchPolicy: 'network-only',
                variables: { toId: this.order.to, unitId: IDS.unitType.Box },
            });
            let existingStock = result.data.ToOrganizationStock;

            let orgStock = this.order.OrderItems.map(o => {
                let obj, stock = existingStock.find(s => s.productId === o.productId);
                if(stock) obj = { id:stock.id, quantity:parseInt(stock.quantity) + parseInt(o.quantity), unitId:stock.unitId };
                else obj = { quantity: parseInt(o.quantity), unitId:IDS.unitType.Box };
                obj['organizationId'] = this.order.to;
                obj['productId'] = o.productId;
                return obj;
            });

            let response = await this.$apollo.mutate({
                mutation: UPDATE_ORDER_WITH_ORGANIZATION_STOCK,
                variables : {
                    order: this.form,
                    id: this.orderId,
                    orgStock,
                    updateColumnsOrgStock: ['unitId', 'quantity']
                }
            });
            if(response.data){
                this.toaster = { enable: true, color: 'green', message: 'Order Status updated successfully' };
                this.formLoading = false;
                this.closeDialog()
            }
        },
        closeDialog(){
            this.$refs.form.resetValidation();
            this.form = {...form};
            this.order = null;
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>

</style>