import gql from "graphql-tag";

export const GET_ORDER_STATUS = gql`
  query orderStatuses{
    statuses: OrderStatus{
      id
      name
    }
  }
`

export const GET_SALES_ORDER = gql`
query getSalesOrder($id:uuid!){
    PurchaseOrders(where:{id:{_eq:$id}}){
        id
        orderNum
        tallyInvoiceNum
        invoiceDate
        status
        gstObject
        totalAmount
        gstPrice
        finalAmount
        roundOfValue
        created_at
        updated_at
        expectedDeliveryDate
        to
        Distributor{
            id
            name
            mobile
            orgCode
        }
        From{
            id
            name
        }
        CreatedBy{
            id
            name
        }
        OrderItems{
            id
            productId
            quantity
            price
            perUnitPrice
            Unit{
                id
                unit
            }
            Product{
                id
                name
                image
                weight
                bunchKg
                colorCode
                bunchPrice
                packageType
                displayName
            }
        }
    }
}`
